<template>
    <b-modal
        v-model="config.model"
        :size="config?.size ?? 'xl'"
        no-close-on-backdrop
        :id="id + '-modal'"
        :title="config?.title ?? ''"
        :scrollable="config?.scrollable ?? false"
        :hide-footer="config?.hideFooter ?? false"
        :dialog-class="`custom-modal ${config?.class ?? ''}`"
    >
        <template #modal-header>
            <h5 id="modal-footer-lg___BV_modal_title_" class="modal-title">{{ config?.title ?? '' }}</h5>
            <button type="button" aria-label="Close" class="close" @click="handleCloseModal">×</button>
        </template>
        <b-container fluid>
            <slot />
        </b-container>
        <template #modal-footer>
            <div>
                <button type="button" class="btn btn-light mr-3" @click="handleCloseModal">
                    {{ $t('btn.cancel') }}
                </button>
                <button type="button" class="btn btn-primary ml-3" @click="handleSave">
                    {{ config?.titleBtn ?? $t('btn.save') }}
                </button>
            </div>
        </template>
    </b-modal>
</template>

<script>
export default {
    name: 'ModalCommon',
    components: {},
    props: {
        id: {
            type: String,
            required: false,
            default: ''
        },
        config: {
            type: Object,
            required: true,
            default: () => ({
                title: '',
                model: false,
                scrollable: false,
                hideFooter: false,
                size: 'xl',
                class: '',
                titleBtn: this.$t('btn.save')
            })
        }
    },
    data() {
        return {};
    },
    methods: {
        handleCloseModal() {
            this.config.model = false;
            this.$emit('close');
        },

        handleSave() {
            this.config.model = false;
            this.$emit('save');
        }
    },
    watch: {}
};
</script>

<style lang="scss">
.custom-modal {
    .modal-content {
        .modal-footer {
            justify-content: center;
        }
    }
}
</style>
