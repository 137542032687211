<!-- eslint-disable no-useless-escape -->
<script>
import Layout from '@/views/layouts/main';
import PageHeader from '@/components/PageHeader/main.vue';
import appConfig from '@/app.config';
import { masterComputed, masterMethods, partnersMethods, checkPermission } from '@/state/helpers';
import { email, required, maxLength } from 'vuelidate/lib/validators';
import { regexUrl } from '@/utils/format';
import { arrayTypeFixed } from '@/config/var-common';
import KeyWordTag from '@/components/KeywordTag/main.vue';
import { getAddressByZip, getPostCodeByAddress } from 'postcode-address-get';
import { showMessage } from '@/utils/messages';
import Company from '@/components/Company/main.vue';
import LoadingIcon from '@/components/Loading/main.vue';
import Footer from '@/components/Footer/main.vue';
import ModalCommon from '@/components/Modal/main.vue';
import { InputText, InputCheckBox } from '@/components/Input';
import i18n from '@/i18n';
/**
 * Advanced-form component
 */
export default {
    page: {
        title: '外部パートナー新規登録',
        meta: [{ name: 'description', content: appConfig.description }]
    },
    components: {
        LoadingIcon,
        Company,
        Layout,
        PageHeader,
        KeyWordTag,
        Footer,
        ModalCommon,
        InputText,
        InputCheckBox
    },
    data() {
        return {
            title: '外部パートナー新規登録',
            index: 1,
            loading: false,
            isUrl: true,
            items: [
                {
                    text: 'ダッシュボード',
                    href: '/'
                },
                {
                    text: this.$route.query.id ? '外部パートナー一覧' : '',
                    href: '/partners'
                },
                {
                    text: this.title ? this.title : '外部パートナー新規登録',
                    active: true
                }
            ],
            id: this.$route.query.id,
            form: {
                corp_number: '',
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                note: '',
                province: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                keyword: '',
                tools: [{ tool: '' }],
                partner_master_order_scopes: [],
                mailbox_address: '',
                direct_address: ''
            },
            listDataDepartment: arrayTypeFixed,
            submitted: false,
            submitform: false,
            submit: false,
            typesubmit: false,
            configKeyword: {
                error: false,
                arrayTag: []
            },
            listMasterOrderScope: [],
            listOrderFilterLv1: [],
            listOrderFilterLv2: [],
            listChecked: {
                msOrderLv1: [],
                msOrderLv2: []
            },
            listRadio: {
                msOrderLv2: {},
                msOrderLv4: {}
            },
            configModalCommon: {
                title: '受注範囲',
                model: false,
                scrollable: true,
                hideFooter: false,
                class: 'modal-custom-partner'
            },
            tableScopeOrder: [],
            configMailbox: {
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pagePartner.mailboxAddress')
            },
            configDirect: {
                isMaxLength: false,
                error: false,
                errorField: i18n.t('pagePartner.directAddress')
            }
        };
    },
    computed: {
        ...masterComputed
    },
    validations: {
        form: {
            name: { required, maxLength: maxLength(255) },
            kana_name: {
                required,
                maxLength: maxLength(255),
                katakana() {
                    if (this.form.kana_name && this.form.kana_name.length < 255) {
                        const katakanaRegex = /^[\u30A0-\u30FF\s]+$/u;

                        if (katakanaRegex.test(this.form.kana_name)) {
                            return true;
                        } else {
                            return false;
                        }
                    }
                    return true;
                }
            },
            postal_code: { required, maxLength: maxLength(20) },
            province: { required, maxLength: maxLength(255) },
            address_1: { required, maxLength: maxLength(255) },
            address_2: { maxLength: maxLength(255) },
            url: {},
            keyword: { maxLength: maxLength(255) },
            email: { required, email, maxLength: maxLength(255) },
            phone_number: { maxLength: maxLength(20) },
            tools: {
                $each: {
                    tool: { maxLength: maxLength(255) }
                }
            }
        }
    },
    beforeRouteUpdate(to, from, next) {
        // Xác định nếu có tham số id trong đường dẫn mới
        const queryId = to.query.id;
        if (!queryId) {
            this.resetForm();
            this.title = '外部パートナー新規登録';
            this.items[2].text = '外部パートナー新規登録';
            this.handleCloseScopeOrder();
        }
        // Gọi next để tiếp tục chuyển route
        next();
    },
    async mounted() {
        await this.handleGetMasterOrderScope();
        if (this.$route.query.id) {
            this.getDetail(this.$route.query.id);
            this.title = '外部パートナー新規編集';
            this.items[2].text = '外部パートナー新規編集';
        }
    },
    methods: {
        ...masterMethods,
        ...partnersMethods,

        async handleGetMasterOrderScope() {
            this.loading = true;
            try {
                const resultMasterOrder = await this.getMasterOrderScope();
                if (resultMasterOrder.code !== 200) return;
                this.listMasterOrderScope = resultMasterOrder.data;
            } catch (error) {
                console.log(error);
            } finally {
                this.loading = false;
            }
        },

        handleData(data) {
            if (data) {
                this.form.name = data.name;
                this.form.corp_number = data.corporate_number;
                this.form.kana_name = data.furigana;
                this.form.postal_code = data.post_code;
                this.form.address_1 = data.city ? data.city.name : '';
                this.form.address_2 = data.street_number;
                this.form.province = data.prefecture ? data.prefecture.name : '';
            }
        },
        inputCorporate() {
            let query = [`method=number`, `number=${this.form.corp_number}`];
            this.findCompany(query.join('&')).then((data) => {
                if (data.code == 200) {
                    this.handleData(data.data[0]);
                }
            });
        },
        resetForm() {
            this.form = {
                name: '',
                kana_name: '',
                email: '',
                phone_number: '',
                note: '',
                province: '',
                address_1: '',
                address_2: '',
                url: '',
                postal_code: '',
                account_id: null,
                chatwork_id: '',
                chatwork_token: '',
                tools: [{ tool: '' }],
                partner_master_order_scopes: [],
                mailbox_address: '',
                direct_address: ''
            };
            this.configKeyword.arrayTag = [];
            this.configKeyword.error = false;
        },
        checkPermiss(listRole) {
            return checkPermission(listRole);
        },

        inputCode() {
            if (!this.form.postal_code) {
                this.form.province = this.form.address_1 = '';
            } else {
                if (this.form.postal_code.length < 3) return;
                getAddressByZip(this.form.postal_code, (address) => {
                    if (address.status == 200) {
                        this.form.province = address.prefecture;
                        this.form.address_1 = address.city + address.area;
                    }
                });
            }
        },
        inputAddress() {
            getPostCodeByAddress(this.form.province + this.form.address_1, (dataPostcode) => {
                if (dataPostcode.status == 200) this.form.postal_code = dataPostcode.postcode;
            });
        },
        inputUrl() {
            if (this.form.url) {
                this.isUrl = regexUrl(this.form.url);
            } else {
                this.isUrl = true;
            }
        },

        getDetail(id) {
            this.loading = true;
            this.detailPartners(id).then((data) => {
                if (!data.tools || data.tools.length === 0) {
                    data.tools = [{ tool: '' }];
                }
                this.form = data;
                this.loading = false;
                this.configKeyword.arrayTag = data.keywords;
                this.handleMapDataRegisted();
                this.handleSaveScopeOrder();
            });
        },

        formSubmit() {
            this.submitted = true;
            // stop here if form is invalid
            this.$v.$touch();
            let check = this.handleValidate(); // check validate
            if (this.$v.$invalid || !this.isUrl || this.configKeyword.error || !check) return;

            this.register();
        },

        handleValidate() {
            let check = true;
            if (this.form.mailbox_address && this.form.mailbox_address.length > 255) {
                this.configMailbox.isMaxLength = true;
                this.configMailbox.error = true;
                check = false;
            } else {
                this.configMailbox.isMaxLength = false;
                this.configMailbox.error = false;
            }

            if (this.form.direct_address && this.form.direct_address.length > 255) {
                this.configDirect.isMaxLength = true;
                this.configDirect.error = true;
                check = false;
            } else {
                this.configDirect.isMaxLength = false;
                this.configDirect.error = false;
            }
            return check;
        },

        register() {
            if (this.form.name && !this.loading) {
                this.loading = true;
                let dataObject = Object.assign({}, this.form);
                dataObject.keywords = this.configKeyword.arrayTag;
                dataObject.tools = this.form.tools.filter((item) => item.tool !== '');
                dataObject.partner_master_order_scopes = this.handleMapDataSever(this.tableScopeOrder, this.form.partner_master_order_scopes);

                if (this.$route.query.id) {
                    if (!dataObject.password) {
                        delete dataObject.password;
                    }

                    this.updatePartners(dataObject).then((data) => {
                        if (data.code == 200) {
                            showMessage(data.code, this.$bvToast, '外部パートナーが更新されました。');
                            this.getDetail(this.$route.query.id);
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('pagePartner.name_unique'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                } else {
                    this.registerPartners(dataObject).then((data) => {
                        if (data.code == 200) {
                            this.$router
                                .push({
                                    path: '/partner/view',
                                    query: {
                                        id: data.data.id
                                    }
                                })
                                .then(() => {
                                    showMessage(data.code, this.$bvToast, '外部パートナーが登録されました。');
                                });
                        } else {
                            if (data.message === 'name.unique') {
                                showMessage(data.code, this.$bvToast, this.$t('pagePartner.name_unique'));
                            } else {
                                showMessage(data.code, this.$bvToast, this.$t(data.message));
                            }
                            this.loading = false;
                        }
                    });
                }
            }
        },

        // start handle modal order scope
        handleChangeOrderLv1(itemLv1) {
            if (this.listChecked.msOrderLv1.includes(itemLv1.id)) {
                this.listOrderFilterLv1.push(itemLv1);
                this.listOrderFilterLv1 = this.listOrderFilterLv1.sort((aLv1, bLv1) => aLv1.id - bLv1.id);
            } else {
                this.listOrderFilterLv1 = this.listOrderFilterLv1.filter((item) => item.id !== itemLv1.id);
                itemLv1.data.forEach((lv1) => {
                    this.listChecked.msOrderLv2 = this.listChecked.msOrderLv2.filter((itemLv2) => lv1.id !== itemLv2);
                    this.listOrderFilterLv2 = this.listOrderFilterLv2.filter((orderFl2) => orderFl2.id !== lv1.id);
                });
            }
        },

        handleChangeOrderLv2(itemLv2) {
            if (this.listChecked.msOrderLv2.includes(itemLv2.id)) {
                this.listOrderFilterLv2.push(itemLv2);
                this.listOrderFilterLv2 = this.listOrderFilterLv2.sort((aLv2, bLv2) => aLv2.id - bLv2.id);
            } else {
                this.listOrderFilterLv2 = this.listOrderFilterLv2.filter((item) => item.id !== itemLv2.id);
            }
        },

        handleVisibleLv2(itemLv1, itemLv2) {
            const result = itemLv1.data.map((item) => item.id).includes(itemLv2.id);
            return result;
        },

        handleSaveScopeOrder() {
            this.tableScopeOrder = this.listMasterOrderScope
                .flatMap((itemOrigin) => {
                    if (this.listChecked.msOrderLv1.includes(itemOrigin.id)) {
                        return {
                            id: itemOrigin.id,
                            value: itemOrigin.value,
                            data: itemOrigin.data
                                .flatMap((itemLv2) => {
                                    if (this.listChecked.msOrderLv2.includes(itemLv2.id)) {
                                        return {
                                            id: itemLv2.id,
                                            value: itemLv2.value,
                                            data: []
                                        };
                                    }
                                    return undefined;
                                })
                                .filter((oLv2) => oLv2 !== undefined)
                        };
                    }
                    return undefined;
                })
                .filter((o) => o !== undefined);
        },

        handleMapDataSever(tableScopeOrder, partner_master_order_scopes) {
            let dataAfterMap = [];
            tableScopeOrder.forEach((itemTableScope, indexTableScope) => {
                if (partner_master_order_scopes.length <= 0) {
                    dataAfterMap.push({
                        large_item: itemTableScope.id,
                        medium_items: itemTableScope.data.map((itemLv2) => {
                            return {
                                medium_item: itemLv2.id,
                                small_items: []
                            };
                        })
                    });
                } else {
                    if (partner_master_order_scopes[indexTableScope]?.id) {
                        if (itemTableScope.id === partner_master_order_scopes[indexTableScope].large_item.id) {
                            dataAfterMap.push({
                                ...(partner_master_order_scopes[indexTableScope]?.id !== null && {
                                    id: partner_master_order_scopes[indexTableScope].id
                                }),
                                large_item: itemTableScope.id,
                                medium_items: itemTableScope.data.map((itemLv2, idxLv2) => {
                                    const mediumItemId = partner_master_order_scopes[indexTableScope].medium_items[idxLv2]?.id ?? null;
                                    return {
                                        ...(mediumItemId !== null && { id: mediumItemId }),
                                        medium_item: itemLv2.id,
                                        small_items: []
                                    };
                                })
                            });
                        } else {
                            dataAfterMap.push({
                                large_item: itemTableScope.id,
                                medium_items: itemTableScope.data.map((itemLv2) => {
                                    return {
                                        medium_item: itemLv2.id,
                                        small_items: []
                                    };
                                })
                            });
                        }
                    } else {
                        dataAfterMap.push({
                            large_item: itemTableScope.id,
                            medium_items: itemTableScope.data.map((itemLv2) => {
                                return {
                                    medium_item: itemLv2.id,
                                    small_items: []
                                };
                            })
                        });
                    }
                }
            });
            return dataAfterMap;
        },

        handleCloseScopeOrder() {
            this.listChecked = {
                msOrderLv1: [],
                msOrderLv2: []
            };
            this.listRadio = {
                msOrderLv2: {},
                msOrderLv4: {}
            };
            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];
            this.handleMapDataRegisted();
        },

        handleOpenScopeOrder() {
            this.configModalCommon.model = true;

            this.listOrderFilterLv1 = [];
            this.listOrderFilterLv2 = [];

            // check data be trả về
            this.listChecked.msOrderLv1.forEach((idOrigin) => {
                const obj = this.listMasterOrderScope.find((x) => x.id === idOrigin);
                if (obj) {
                    this.handleChangeOrderLv1(obj);
                    obj.data.forEach((lv1) => {
                        this.handleChangeOrderLv2(lv1);
                    });
                }
            });
        },
        // end handle modal order scope,

        handleMapDataRegisted() {
            if (this.form.partner_master_order_scopes.length > 0) {
                this.form.partner_master_order_scopes.forEach((itemLv1) => {
                    if (!this.listChecked.msOrderLv1.includes(itemLv1.large_item.id)) {
                        this.listChecked.msOrderLv1.push(itemLv1.large_item.id);
                        this.listOrderFilterLv1 = this.listOrderFilterLv1.sort((aLv1, bLv1) => aLv1.id - bLv1.id);
                    }
                    if (itemLv1.medium_items.length > 0) {
                        itemLv1.medium_items.forEach((itemLv2) => {
                            if (!this.listChecked.msOrderLv2.includes(itemLv2.medium_item.id)) {
                                this.listChecked.msOrderLv2.push(itemLv2.medium_item.id);
                                this.listOrderFilterLv2 = this.listOrderFilterLv2.sort((aLv2, bLv2) => aLv2.id - bLv2.id);
                            }
                            this.listRadio.msOrderLv2[itemLv1.large_item.id] = itemLv2.medium_item.id;
                        });
                    }
                });
            }
        },

        addTools() {
            this.form.tools.splice(this.form.tools.length, 0, { tool: '' });
        },

        deleteTool(index) {
            this.form.tools.splice(index, 1);
        }
    }
};
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row form__body--card form__common--center" v-if="!loading">
            <div class="col-lg-12">
                <div class="card">
                    <div class="card-body">
                        <div class="row">
                            <div class="col-sm-1"></div>
                            <div class="col-sm-6">
                                <form class="needs-validation" @submit.prevent="formSubmit" autocomplete="off">
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom01"
                                            >{{ $t('pagePartner.name') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <div style="position: absolute; left: 100%; top: 5px">
                                                <company @data="handleData" />
                                            </div>
                                            <InputText
                                                id="validationCustom01"
                                                :model.sync="form.name"
                                                :config="{
                                                    isRequired: !$v.form.name.required,
                                                    isMaxLength: !$v.form.name.maxLength,
                                                    error: submitted && $v.form.name.$error,
                                                    errorField: $t('pagePartner.name')
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom02"
                                            >{{ $t('pagePartner.name_kana') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom02"
                                                :model.sync="form.kana_name"
                                                :config="{
                                                    isRequired: !$v.form.kana_name.required,
                                                    isMaxLength: !$v.form.kana_name.maxLength,
                                                    isKatakana: !$v.form.kana_name.katakana,
                                                    error: submitted && $v.form.kana_name.$error,
                                                    errorField: $t('pagePartner.name_kana')
                                                }"
                                            />
                                        </div>
                                    </div>
                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05">法人番号 </label>
                                        <div class="col-sm-6">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.corp_number"
                                                :config="{
                                                    error: false
                                                }"
                                                @change="inputCorporate"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom03">{{ $t('pagePartner.phone') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom03"
                                                :model.sync="form.phone_number"
                                                :config="{
                                                    isMaxLength: !$v.form.phone_number.maxLength,
                                                    isNumber: true,
                                                    maxlength: 20,
                                                    error: submitted && $v.form.phone_number.$error,
                                                    errorField: $t('pagePartner.phone')
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom04"
                                            >{{ $t('pagePartner.postalCode') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom04"
                                                :model.sync="form.postal_code"
                                                :config="{
                                                    isRequired: !$v.form.postal_code.required,
                                                    isMaxLength: !$v.form.postal_code.maxLength,
                                                    maxlength: 20,
                                                    isNumber: true,
                                                    error: submitted && $v.form.postal_code.$error,
                                                    errorField: $t('pagePartner.postalCode')
                                                }"
                                                @input="inputCode"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom05"
                                            >{{ $t('pagePartner.province') }}<span class="text-danger">*</span>
                                        </label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.province"
                                                :config="{
                                                    isRequired: !$v.form.province.required,
                                                    isMaxLength: !$v.form.province.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.province.$error,
                                                    errorField: $t('pagePartner.province')
                                                }"
                                                @input="inputAddress"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom06"
                                            >{{ $t('pagePartner.address_1') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom06"
                                                :model.sync="form.address_1"
                                                :config="{
                                                    placeholder: $t('pldCommon.address_1'),
                                                    isRequired: !$v.form.address_1.required,
                                                    isMaxLength: !$v.form.address_1.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.address_1.$error,
                                                    errorField: $t('pagePartner.address_1')
                                                }"
                                                @input="inputAddress"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom07">{{ $t('pagePartner.address_2') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom07"
                                                :model.sync="form.address_2"
                                                :config="{
                                                    placeholder: $t('pldCommon.address_2'),
                                                    isMaxLength: !$v.form.address_2.maxLength,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.address_2.$error,
                                                    errorField: $t('pagePartner.address_2')
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom08">{{ $t('pagePartner.description') }}</label>
                                        <div class="col-sm-12">
                                            <textarea id="validationCustom08" v-model="form.note" class="form-control"></textarea>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom09">{{ $t('pagePartner.url') }}</label>
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom05"
                                                :model.sync="form.url"
                                                :config="{
                                                    isUrl: !isUrl,
                                                    maxlength: 255,
                                                    error: submitted && !isUrl,
                                                    errorField: $t('pagePartner.url')
                                                }"
                                                @change="inputUrl"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom10"
                                            >{{ $t('pagePartner.email') }} <span class="text-danger">*</span></label
                                        >
                                        <div class="col-sm-12">
                                            <InputText
                                                id="validationCustom10"
                                                :model.sync="form.email"
                                                :config="{
                                                    isRequired: !$v.form.email.required,
                                                    isMaxLength: !$v.form.email.maxLength,
                                                    isEmail: !$v.form.email.email,
                                                    maxlength: 255,
                                                    error: submitted && $v.form.email.$error,
                                                    errorField: $t('pagePartner.email')
                                                }"
                                            />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom11">{{ $t('pagePartner.type') }} </label>
                                        <div class="col-sm-12">
                                            <button type="button" class="btn btn-sm btn-info" @click="handleOpenScopeOrder()">詳細</button>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="mailboxAddress">{{ $t('pagePartner.mailboxAddress') }}</label>
                                        <div class="col-sm-12">
                                            <InputText id="mailboxAddress" :model.sync="form.mailbox_address" :config.sync="configMailbox" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="directAddress">{{ $t('pagePartner.directAddress') }}</label>
                                        <div class="col-sm-12">
                                            <InputText id="directAddress" :model.sync="form.direct_address" :config.sync="configDirect" />
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom12">{{ $t('pagePartner.tool') }}</label>
                                        <div class="col-sm-12">
                                            <div v-for="(itemTool, idxTool) in form.tools" :key="`${idxTool}_toolss`">
                                                <div class="d-flex align-items-center mb-3">
                                                    <InputText
                                                        :id="`validationTool_${idxTool}`"
                                                        class="flex-fill"
                                                        :model.sync="itemTool.tool"
                                                        :config="{
                                                            isMaxLength: !$v.form.tools.$each[idxTool].tool.maxLength,
                                                            error: submitted && $v.form.tools.$each[idxTool].tool.$error,
                                                            errorField: $t('pagePartner.tool')
                                                        }"
                                                    />
                                                    <div class="col-sm-2">
                                                        <div class="d-flex" style="gap: 10px">
                                                            <button
                                                                type="button"
                                                                class="btn btn-sm btn-outline-info"
                                                                style="border-radius: 50%"
                                                                @click="addTools()"
                                                            >
                                                                <i class="fa fa-plus"></i>
                                                            </button>
                                                            <button
                                                                type="button"
                                                                class="btn btn-sm btn-outline-danger"
                                                                style="border-radius: 50%"
                                                                :disabled="form.tools.length === 1"
                                                                @click="deleteTool(idxTool)"
                                                            >
                                                                <i class="fa fa-minus"></i>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="form-group">
                                        <label class="col-sm-3 mw--form" for="validationCustom12">{{ $t('pagePartner.searchKeyword') }}</label>
                                        <div class="col-sm-12">
                                            <KeyWordTag :config="configKeyword" />
                                            <span v-if="configKeyword.error" class="text-danger">
                                                {{
                                                    $t('validateField.max255', {
                                                        field: $t('pagePartner.searchKeyword')
                                                    })
                                                }}
                                            </span>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer>
                <div class="d-flex justify-content-center">
                    <div class="text-center flex-fill">
                        <button
                            type="button"
                            class="btn btn-light mr-3 btn-sm"
                            @click="
                                $router.push({
                                    path: '/partners'
                                })
                            "
                        >
                            {{ $t('btn.cancel') }}
                        </button>
                        <button
                            type="submit"
                            class="btn btn-primary ml-3"
                            v-if="checkPermiss('partner.register') || (checkPermiss('partner.edit') && $route.query.id)"
                            @click="formSubmit"
                        >
                            {{ $route.query.id ? $t('btn.save') : $t('btn.register') }}
                        </button>
                    </div>
                </div>
            </Footer>
        </div>
        <div class="d-flex justify-content-center" v-else>
            <LoadingIcon />
        </div>

        <ModalCommon id="ModalOrder" :config="configModalCommon" @save="handleSaveScopeOrder()" @close="handleCloseScopeOrder()">
            <form class="needs-validation" autocomplete="off">
                <div class="form-group row">
                    <label class="col-sm-2 mw--form" for="validationCustom05">受注範囲</label>
                    <div class="col-sm-9">
                        <div class="d-flex flex-wrap">
                            <div
                                class="custom-control custom-checkbox mr-4 py-1"
                                style="min-width: 100px"
                                v-for="orderLv1 in listMasterOrderScope"
                                :key="orderLv1.id"
                            >
                                <InputCheckBox
                                    :model.sync="listChecked.msOrderLv1"
                                    :config="{
                                        id: `check_app${orderLv1.id}`,
                                        value: orderLv1.id,
                                        label: orderLv1.value
                                    }"
                                    @change="handleChangeOrderLv1(orderLv1)"
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div v-if="listChecked.msOrderLv1.length">
                    <div
                        v-for="itemFilterLv1 in listOrderFilterLv1"
                        :key="`${itemFilterLv1.id}-filter1`"
                        class="py-3 mt-3"
                        style="box-shadow: rgba(0, 0, 0, 0.2) 0px 5px 15px; border-radius: 10px"
                    >
                        <div>
                            <div class="ml-1 my-y">
                                <label class="custom-control px-3">{{ itemFilterLv1.value }}</label>
                            </div>
                            <div class="form-group row">
                                <div class="col-sm-1"></div>
                                <div class="col-sm-11 row">
                                    <div v-for="(dataFilterLv1, idxF1) in itemFilterLv1.data" :key="`${idxF1}-medium_item_filter1`">
                                        <InputCheckBox
                                            class="mr-4 py-1"
                                            style="min-width: 100px"
                                            :model.sync="listChecked.msOrderLv2"
                                            :config="{
                                                id: `check_app${dataFilterLv1.id}`,
                                                value: dataFilterLv1.id,
                                                label: dataFilterLv1.value
                                            }"
                                            @change="handleChangeOrderLv2(dataFilterLv1)"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ModalCommon>
    </Layout>
</template>
<style lang="scss">
.modal-custom-partner {
    .modal-content {
        height: 100vh;
    }
}
</style>
